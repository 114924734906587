.signup-container {
    width: 100%;

    max-height: fit-content;


    display: flex;
    flex-direction: row;
    position: fixed;



    color: white;
}

.signup-bg {
    background-color: #00171F;
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.signup-bg div img {
    width: 200px;
    height: 200px;
    margin-left: 40px;

}

.signup-bg div h1 {
    font-size: 46px;
    font-weight: 600;
    margin-left: 30px;
    margin-bottom: 14px;
}

.signup-bg div p {
    font-size: 36px;
    margin-bottom: 14px;
    font-weight: 600;

}

.signup-field {
    width: 70%;

    height: 40px;
    display: flex;
    
    justify-content: center;
    /* justify-content: space-between; */
    /* gap:5px; */
    align-items: center;
    border: 1px solid #D1D5DB;
    margin: auto;
    background-color: white;
    border-radius: 50px;
    margin-top: 17px;

}

/* .signup-container  a{
    text-decoration: none;
    color: #0fb447;
} */

.signup-detail {
    height: 100vh;

    width: 50%;

    background-color: white;
    margin-bottom: 10px;
}

.signup-detail h2 {
    /* text-align: center; */
    color: black;
    margin-left: 15%;
    margin-top: 10px;
}

.signup-detail p {
    /* text-align: center; */
    color: black;
    margin-top: 10px;
    margin-left: 15%;

}

.signup-field input {
    width: 100%;
    padding: 3px;

    border-radius: 50px;



    height: 100%;
    border: none;
    padding: 0px 20px;

    background-color: #E5E5E5;
    outline: none;

}
.signup-select{
    width: 70%;

    height: 40px;
    display: flex;
    
    justify-content: center;
    /* justify-content: space-between; */
    /* gap:5px; */
    align-items: center;
    border: 1px solid #D1D5DB;
    margin: auto;
    background-color: white;
    border-radius: 2px;
    margin-top: 17px;  
}
.signup-select select {
    width: 100%;
    background-color: #E5E5E5;
    height: 100%;
    border-radius: 2px;
    border:none;
    outline: none;
    padding: 0px 20px;
    border: 1px solid hsla(0, 0%, 0%, 0.237);

}
.display-error p{
    /* border: 1px solid red; */
    align-items: center;
    margin-right: 20%;
}
#names {
    display: flex;
    gap: 05px;
    width: 70%;
    margin: auto;
}

.names {
    width: 100%;
}

#address-sub {
    display: flex;
    width: 90%;
    margin: auto;
    height: 40px;
    gap: 10px;
}

.number {
    display: flex;
    width: 70%;
    height: 40px;
    position: relative;
    margin: 17px auto;
    /* margin: auto; */
    gap: 10px;
    /* border: 2px solid red; */
}

.number div {
    width: 50%;
    /* height: 60px; */
    background-color: #E5E5E5;
    height: 100%;
    border-radius: 50px;
    outline: none;

}

.number div input {
    width: 100%;
    /* height: 60px; */
    background-color: #E5E5E5;
    height: 100%;
    border: none;
    padding: 0px 20px;

    outline: none;
    border-radius: 50px;
    /* outline: none; */

}

.password {
    display: flex;
    width: 70%;
    height: 40px;
    position: relative;
    margin: 17px auto;
    /* margin: auto; */
    gap: 10px;
}

.password div {
    width: 50%;
    /* height: 60px; */
    background-color: #E5E5E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-radius: 50px;
    outline: none;

}

.password div input {
    width: 90%;
    /* height: 60px; */
    background-color: #E5E5E5;
    height: 100%;
    border: none;
    padding: 0px 10px;

    outline: none;
    border-radius: 50px;
    /* outline: none; */
}

#signaddress {
    width: 70%;
    margin: 0px auto;
    padding: 5px;

    border: 1px solid hsla(0, 0%, 0%, 0.251);
    align-content: center;
    color: black;

}

#signaddress .signup-field {
    margin-top: 0px;
    margin-bottom: 10px;

}

.password-field img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

#buttons-id {
    height: 40px;
    display: flex;
    color: hsla(0, 0%, 0%, 0.715);
    justify-content: center;
    align-items: center;
    /* gap:10px; */
    margin: auto;
    /* border: 2px solid red; */
    margin-top: -10px;
    width: 70%;

}

#buttons-id button {
    color: white;
}

#buttons-id h2 {
    font-size: 15px;
    margin-top: 25px;
}

#signup-register {
    width: 30%;
    height: 45px;
    margin: auto;

    border-radius: 4px;
    border: none;

    /* float: right; */
    background-color: #021742;
    margin-top: 17px;
}

#signup-register-loading {
    width: 30%;
    height: 45px;
    margin: auto;
    border: none;
    border-radius: 4px;

    /* float: right; */
    background-color: #edf1fd;

    margin-top: 17px;
}

#signup-register-loading span {
    display: flex;
    justify-content: center;
    color: #021742;

}

#signup-form {
    margin: auto;
}

@media (max-width: 480px) {
    .signup-bg {
        display: none;
    }
    .signup-field {
        width: 90%;
    }
  
    #names {
        width: 90%;
        
    }
    .number{
        width: 90%;
    }
    #signaddress,.password,.signup-select{
        width: 90%;
    }
    .signup-detail {
        width: 100%;
        margin-top: 35px;
    }

    .buttons {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50vh;

    }
    #buttons-id{
        width: 90%;
    }

    #signup-register {
        width: 100%;
        height: 85px;
        margin: auto;

        border-radius: 4px;
        padding: 15px;
        margin-top: 80px;

    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .signup-detail {
        margin-top: 10%;

    }

    form {
        display: flex;
        flex-direction: column;

        justify-content: space-evenly;
    }

    #names {
        flex-direction: column;
    }

    .buttons {
        flex-direction: column;
    }

    #signup-register {
        width: 100%;
        margin-top: 100px;
        padding: 15px;
    }

}