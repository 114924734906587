.profile-container {
  background-color: white;
  //  border: 2px solid red;
  font-size: larger;

  width: 96%;
  margin: auto;
  display: flex;
  align-content: center;
  /* flex-direction: column; */
  justify-content: space-between;

  align-items: center;
  height: 10%;
  .sidebar-menu img {
    width: 20px;
  }
  .profile {
    cursor: pointer;
    width: fit-content;
    margin-right: 10px;
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    //  border: 2px solid red;
    .float-badge {
      position: absolute;
      top: 43px;
      right: -10px;
      height: fit-content;
      width: 170px;
      display: none;
      z-index: 1;
      background-color: #edf1fd;
      color: #021742;
      padding: 5px;
      border-radius: 5px;
      // border: 1px solid red;
      box-shadow: 0 4px 4px 0 rgba(227, 224, 224, 0.2);
      &.visible {
        display: block; /* Show when .visible is added */
      }
      .float-links {
        display: flex;
        flex-direction: column;
        text-align: center;
        text-align: left;

        gap: 20px;
        font-size: small;
        .logout {
          // border: 1px solid red;
          padding: 5px;
          background-color: #021742;
          color: #edf1fd;
          text-align: center;
        }
      }
      
      @media (max-width: 480px) {
        margin-right: 0px;
        width: 200px;
        // border: 1px solid red;
        :hover {
          background-color: #edf1fd;
        }
      }
    }
    &.openProfile {
      display: block;
    }
  }

  .profile .img {
    width: 45px;
    height: 45px;
    border: 3px solid #021742;
    background-color: #021742;
    border-radius: 50%;
    font-weight: bolder;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #edf1fd;
    // border: 2px solid yellow;
  }

  .profile p {
    font-size: 14px;
    color: #021742;
  }
  .sidebar-menu {
    width: 50%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    img{
      display: none;
    }
    div {
    display: none;
    gap: 5px;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 10px;
  font-size: larger;

    .sidebar-menu {
      width: 50%;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      img{
        display: block;
      }
      div {
      display: flex;
      gap: 5px;
      }
    }
  }
  
}
