@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.slideshow {
  width: 80%;
  max-width: 1200px;
  margin: auto;
  height: fit-content;
  // border: 1px solid yellow;

  .slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content; // Adjust height as needed
    margin-top: 100px;

    .slide-button {
      display: flex;

      button {
        align-items: center;
        margin: auto;
        height: 40px;
        width: 300px;
        border-radius: 4px;
        border: none;
        font-family: "Teko", sans-serif;
        font-weight: 900;
      }
    }
    .slide-text {
      margin-top: 40px;
      padding: 20px;
      // background: rgba(0, 0, 0, 0.5);
      color: white;
      border-radius: 5px;
      text-align: center;
      max-width: 90%;
      font-family: "Outfit", sans-serif;
    }
    @media (max-width: 480px) { 
      margin-top: 10px;
      margin-bottom: 30px;
  } 
  }
  @media (max-width: 768px) {
    font-size: smaller;
  }
}
