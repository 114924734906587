.email-content {
  border: 1.2px solid hsla(0, 0%, 0%, 0.167);
  border-radius: 5px;
  width: 35%;
  height: 60%;
  padding-bottom: 20px;
  display: none;
  flex-direction: column;
  justify-content: center;
  background-image: url("../../assets/images/verified.png");
  background-size: cover; /* Ensures the image covers the container */
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden; /* Prevents content from overflowing the container */
  &.isVisible {
    display: flex;
  }
  .login {
    background-color: #021742; /* Updated color */
    width: 200px;
    margin: auto;
    padding: 6px;
    margin-bottom: 20px;
    border-radius: 4px;
    color: white;
    text-align: center; /* Center the text inside the login button */
    .verify-login {
      color: white;
      text-decoration: none;
    }
  }

  @media (max-width: 480px) {
    width: 95%; /* Adjust width for smaller screens */
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 70%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 70%;
  }
}
