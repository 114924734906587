@import "../../styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Saira:ital,wght@0,100..900;1,100..900&family=Teko:wght@300..700&display=swap");

* {
  margin: 0;
}
.logout-sign{
  position: absolute;
  color: red;
 margin-left: 48%;
 display: none;
 top: 50%;
 span{
  display: flex;
  justify-content: center;
  align-items: center;
 }
 &.open{
  display: block
 }
}
.home-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 70px;
  margin-left: 150px;
  margin-right: 150px;

  .nav-logo {
    img {
      height: 70px;

      width: 80px;
    }
  }
  .nav-links {
    display: flex;
    gap: $base-spacing;
    font-size: 20px;

    .active {
      text-decoration: overline;
    }
    a {
      text-decoration: none;
      color: white;
      font-size: 1em;
      font-family: Teko, sans-serif;
      display: flex;
      align-items: center;
      gap: 5px;

      .img {
        width: 45px;
        height: 45px;
        border: 3px solid #edf1fd;
        background-color: #edf1fd;
        border-radius: 50%;
        font-weight: bolder;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        color: #021742;
      }
      .arrow-down {
        border: none;
        width: 20px;
        filter: invert(70%);
      }
      &:hover {
        color: darken(white, 20%);
      }
    }
    a.account {
      padding: 5px;
      border-radius: 51px;
      position: absolute;
      // display: none;
      position: relative;
      font-size: small;
      .float-badge {
        position: absolute;
        top: 50px;
        right: 10px;
        height: fit-content;
        width: 170px;
        display: none;
        background-color: #edf1fd;
        color: #021742;
        padding: 5px;
        border-radius: 5px;
        // border: 1px solid red;
        box-shadow: 0 4px 4px 0 rgba(227, 224, 224, 0.2);
        &.visible {
          display: block; /* Show when .visible is added */
        }
        .float-links {
          display: flex;
          flex-direction: column;
          text-align: center;
          text-align: left;
z-index: 1;
          gap: 20px;
          font-size: small;
          .logout {
            // border: 1px solid red;
            padding: 5px;
            background-color: #021742;
            color: #edf1fd;
            text-align: center;
          }
        }
        
        @media (max-width: 480px) {
          margin-right: -70px;
          width: 200px;
          // border: 1px solid red;
          :hover {
            background-color: #edf1fd;
          }
        }
      }
      &.openProfile {
        display: block;
      }
    }
  }

  .nav-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;

    .bar {
      width: 25px;
      height: 3px;
      background-color: white;
      margin: 4px 0;
      transition: 0.4s;
    }
  }

  @media (max-width: 768px) {
    margin-left: 40px;
    margin-right: 40px;
    height: auto;

    .nav-links {
      display: none;
      position: absolute;
      flex-direction: column;
      width: 70%;
      top: 60px;
      right: 0px;
      text-align: center;
      background: #021742;
      box-shadow: 0 8px 8px 0 rgba(249, 248, 248, 0.1);

      z-index: 1;
      border-radius: 15px;

      a {
        margin: auto;
        padding: 10px;
        border-bottom: 1px solid lighten($primary-color, 20%);
        :hover {
          background-color: $secondary-color;
          color: $primary-color;
        }
      }
    }

    .nav-links.open {
      display: flex;
      // flex-direction: column;
    }

    .nav-toggle {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 40px;
    }
  }
}
