/* CustomToast.css */
.custom-toast {
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, sans-serif;
}

.custom-toast.info {
  background-color: #2196F3;
}

.custom-toast.success {
  background-color: #021742;

}

.custom-toast.error {
  background-color: #021742;
  color: yellow;
}

.toast-close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}
/* Custom CSS for Toast Position */
.custom-toast-top-right {
  top: 20px;
  right: 20px;
}
@media (max-width: 480px) { 
 
  .custom-toast-top-right {
    top: 40px;
    right: 0px;
  }
} 
.custom-toast-bottom-left {
  bottom: 20px;
  left: 20px;
}

.custom-toast-bottom-right {
  bottom: 20px;
  right: 20px;
}

.custom-toast-top-left {
  top: 20px;
  left: 20px;
}
