.login-container {
    width: 100%;
    height: 100vh;

    background-color: #00171F;


}
#single-body{
    width: 100%;
    /* background-image: url('images/bg.png'); */
    height: auto;
    /* border: 3px solid red; */
    background-position: center;
    background-size: cover;
    
    flex-direction: column;
    background-attachment: fixed;
    background-color: #00171F;
}
.wrapper-all-single {
   
    display: flex;
    flex-direction: column;
    background-attachment: fixed; 
    align-items: center;
    justify-content: space-around;
}
.wrapper-all-single img {
    width: 150px;
    height: 120px;
}

.wrapper-all {
    /* background-image: url('images/bg.png'); */
    width: 100%;
    height: 100%;
    /* border: 3px solid red; */
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

}

.wrapper-all img {
    width: 150px;
    height: 120px;
}

.logo {
    display: flex;
    color: white;
    align-items: center;
    width: fit-content;
    height: fit-content;
}

.login-container .logo img {
    width: 150px;
    height: 120px;
    margin: auto;
}

.login-detail {
    height: 400px;
    width: 35%;
    border: 1px solid;
    background-color: #dae2fb;
    border-radius: 4px;
}
.login-detail img{
    height: 17px;
    width: 17px;
    margin-right: 10px;
    margin-left: 5px;
    margin-bottom: 6px;

}