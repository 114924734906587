@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.actions-body {
  // border: 1px solid red;

  margin: auto;
  .action-form {
    .topic {
      height: 35px;
      width: 250px;
    }
    .done {
      display: flex;
      justify-content: space-between;
      // border: 1px solid red;
      font-weight: bold;
      font-size: medium;
      width: 100%;
      padding: 10px;
    }
    .status {
      display: flex;
      justify-content: space-between;
      // border: 1px solid red;
      font-weight: bold;
      font-size: medium;
      width: 100%;
      padding: 10px;
    }
    .assign {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      font-size: medium;
      font-family: "Outfit", sans-serif;

      // border: 1px solid red;
      width: 100%;
      padding: 10px;
    }
    button {
      padding: 15px;
      float: right;
      //  margin-right: 13%;
      padding: 5px;
    }
  }
}
