.dash-nav {
  width: 17%;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
  text-transform: uppercase;
  flex-direction: column;
  height: 100%;
  background-color: white;
  color: hsla(0, 0%, 0%, 0.548);
  cursor: pointer;
  /* border-radius: 50px; */

  div {
    margin-top: 20px;
    margin-left: 10%;
    gap: 10px;
    display: flex;
    .link-to-home {
      margin-top: 20px;
      // margin-left: 10%;
      text-decoration: none;
      color: hsla(0, 0%, 0%, 0.548);

      gap: 10px;
      display: flex;
    }
  }

  .home {
    width: 20px;
    height: 20px;
    filter: invert(70%);
  }
  .logo {
    width: 100px;
  }
  @media (max-width: 480px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 30%;
    // font-size: large;
  }
}
