.main{
    // margin: 0 150px 0px 0px;
    .profile-content{
        width: 80%;
        padding-top: 20px;
        // height: 100vh;;
        height: auto;
        // border: 1px solid red;
        // background-color: #021742;
        margin-left: auto;
        display: flex;
        gap: 20px;
    }
    .profile-image{
        width: 30%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // border: 2px solid red;
        p{
            margin-left: 40px;
            // color: aqua;
            font-size: 30px;

        }
    }
    .profile-data{
        width: 70%;
        margin-top: 1%;
        // height: 100;
    height: auto;
        display: flex;
        flex-direction: column;
        gap: 19px;

    }
    label{
        display: flex;
        flex-direction: column;
        gap:8px;
        width:100%;
        color: black;
    }
    .names,.numbers,.sectors{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .names label,.numbers label{
        width: 49%;

    }
    .names label input,.numbers label input{
        height: 40px;
        background-color: #e5e5e5;
      border: none;
        outline: none;
        padding: 0px 10px;
        border-radius: 20px;
    }
    .sectors label{
        width: 32%;

    }
    .sectors label input{
        // width: 32%;
        height: 40px;
        background-color: #e5e5e5;
        // border: none;
        border: none;
        outline: none;
        padding: 0px 10px;
        border-radius: 20px;  
    }
    .profile-data div{
        width: 100%;


    }
    .profile-input input{
        width: 100%;
        height: 40px;
        background-color: #e5e5e5;
        border: none;
        outline: none;
        // outline: none;
        padding: 0px 10px;
        border-radius: 20px;
    }
    .password-field{
        width: 100;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    .password-field label{
        width: 70%;
    }
    .password-field label input{
        width: 100%;
        height: 40px;
        background-color: #e5e5e5;
        // border: none;
        border: none;
        outline: none;
        padding: 0px 10px;
        border-radius: 20px;   
    }
    .password-field button{
        width: 20%;
        height: 40px;
        margin-top: 28px;
        outline: none;
        border: none;
        color: white;
        border-radius: 3px;
        background-color:#021742;
    }
    .profile-data .all{
        background-color:#021742;
        width: 20%;
        padding: 10px;
        outline: none;
        border: none;
        color: white;
        border-radius: 3px;
    }
    @media (max-width: 480px) { 
        .profile-image{
            width: 95%;
        }
        .profile-content{
            display: block;
        }
        .profile-data{
            width: 95%;
        }
        p{
            margin-left: 0px;
            // color: aqua;
            font-size: 30px;

        }
    
    }
}
