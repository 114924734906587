@import "../../styles/variables.scss";
// about card of staff
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}
// @import ;
.welcome-div {
  height: 100vh;
  // font-family: "Open Sans", sans-serif;
  // border: 1px solid red;
  background-color: $primary-color;
  padding-top: 60px;
 
  .homenav-component {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; // Ensure it's above other elements
    // background-color: $primary-color; // Adjust as needed
  }
  .home-buttons {
    margin: auto;
    // border: 1px solid red;
    margin-left: 150px;
    margin-right: 150px;
    display: flex;
    justify-items: center;
    margin-top: 70px;
    button {
      align-items: center;
      margin: auto;
      height: 40px;
      width: 300px;
      border-radius: 4px;
      border: none;
  font-family: "Teko", sans-serif;
  font-weight: 900;

    }
    :hover {
      background-color: gold;
      color: white;
    }
  }
  @media (max-width: 768px) {
    height: auto;
    .home-buttons {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 40px;

      button {
        align-items: center;
        margin: auto;
        height: 30px;
        width: 240px;
        margin-bottom: 25px;
      }
    }
  }
}
.downimg {
  width: 100%;
  img {
    // border: 1px solid yellow;
    width: fit-content;
    height: 40px;
    margin-top: -40px;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
  }
}
