.admin-nav{
  // border: 1px solid red;
  height: 10vh;
  background-color: #021742;
  color: white;
  display: flex;
  justify-content: space-between;
  .left-side{
    // border: 1px solid yellow;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right-side{
    display: flex;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .notification{
      img{
        width: 20px;
        filter: invert(100%);
      }
    }
    .admin-profile{
      // border: 1px solid red;
      background-color: white;
      color: #021742;
      border-radius: 50px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: bolder;
    }
  }

}