/* about user submit component */
.update-container {

  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  z-index: 1000;
  /* Higher z-index to ensure it overlays other content */
}

.update-case {
  // z-index: 1;
  background-color: white;
  // border: 1px solid hsla(0, 0%, 0%, 0.174);
  height: auto;
  width: 50%;
  margin-top: 2vh;
  // z-index: -1;
}

.update-case form {
  display: flex;
  flex-direction: column;
}



.update-case p {
  margin-left: 15%;
  font-size: 24px;
  font-weight: bold;
}

.update-case label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 15%;

  margin-top: 10px;
}
.update-case label  .file{
  background-color: white;
}

.update-case label .subject ,.topic {
  width: 80%;
  height: 40px;
background-color: #e5e5e5;
  // border-radius: 20px;
  padding: 0px 10px;
  border: none;
  outline: none;
}

.update-case label textarea {
  width: 80%;
  outline: none;
  height: 130px;
background-color: #e5e5e5;
  border-radius: 6px;
}

.update-case button {
  width: 68%;

  height: 43px;

  border: none;
  outline: none;
  border-radius: 6px;
  margin-left: 15%;
  /* margin: auto; */
  margin-top: 10px;
  background-color: #021742;
  color: white;
  margin-bottom: 20px;
  
}

@media (max-width: 480px) {
  .submit-case {
    width: 90%;
    height: 80%;
  }
}
@media (min-width: 768px) and (max-width: 1024px){ 
  
  .submit-case {
    width: 80%;
    height: 80%;
  }


} 