.admin-side-nav{
  height: 90vh;
  width: 15%;
  background-color: #edf1fd;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
.left-side{
  display: flex;
  flex-direction: column;
  gap: 15px;
  img{
    width: 100px;
  }
  .link{
    text-decoration: none;
    color: #021742;
    font-weight: bold;
    padding: 5px;
   border-bottom: 1px solid #021742;
   &.active {
    color: #ffffff; /* Change color for active link */
    background-color: #021742; /* Highlight background */
    font-weight: bold;
  }
  }
  .link:hover{
    background-color: white;
    color: #021742;
  }
}
}