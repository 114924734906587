.cases-table-view {
  width: 100%;
  padding: 5px;
  height: 90vh;
  table {
    width: 100%;
    border-collapse: collapse;
    // border: 1px solid ;
    .case-id {
      width: 200px;
      text-wrap: wrap;
      font-size: small;
    }
    th {
      background-color: #edf1fd;
      color: #021742;
      // font-size: small;
    }
    .th {
      font-size: small;
    }
    .td {
      font-size: small;
      text-align: end;
      box-shadow: 0 1px 8px 0 rgba(237, 241, 253, 2);
    }
    td {
      padding: 5px;
      button {
        background-color: #021742;
        height: 30px;
        cursor: pointer;
        color: white;
        font-weight: 100;
        border: none;
        padding: 3px;
        border-radius: 5px;
      }
      button:hover {
        font-size: 12px;
      }
    }
    .actions {
      display: flex;
      justify-content: space-evenly;
    }
  }
}
