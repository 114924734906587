.prof-detail {
  position: absolute;
  right: 20px;
  top: 0x;
  /* z-index: 1; */
  padding: 10px;
   width: 400px; 
  /* color: red; */
  height: auto;
  background-color: #edf1fd;
  border: 1px solid hsla(0, 0%, 0%, 0.135);
  box-shadow: 2px 2px 2px hsla(0, 0%, 0%, 0.477);
  border-radius: 5px 0px 5px 5px;
  /* display: none; */
  @media (max-width: 480px) {
   width: 300px;
   gap: 20px;
  }
}

#alldetails {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.detailicon {
  width: 15px;
  height: 15px;
}

.profile-data {
  color: hsla(0, 0%, 0%, 0.53);
}

.prof-detail span {
  font-weight: 400;
  font-size: 15px;
  color: black;
}

.profile-footer {
  display: flex;
  justify-content: space-between;
}

.profile-footer img {
  filter: invert(-50%);
}

.edit {
  border: 1px solid blue;
  padding: 2px;
  border-radius: 2px;
}