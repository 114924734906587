.single-case-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.single-case-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 90%;
  height: 90vh;
  // overflow: scroll;
  

}

.single-case-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

