@import "../../styles/variables.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto|Sriracha&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Saira:ital,wght@0,100..900;1,100..900&family=Teko:wght@300..700&display=swap");

$background: #fffffb;
$accent: #ff6b6c;
$otherAccent: #ffc145;
$darkBackground: #5b5f97;

* {
  margin: 0;
  padding: 0;
}
.heading {
  h1 {
    font-size: 2em;
    color: $primary-color; // Example of using a variable from variables.scss
  }

  hr {
    height: 6px;
    width: 80px;
    background-color: rgb(27, 63, 168);
    border: none; // Remove border
    margin: 10px auto; // Center horizontally with margin
  }
}
.boardTeam {
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  margin-left: 150px;
  margin-right: 150px;
  gap: 35px;
  .team {
    display: flex;
    gap: 15px;
    
    #team-container {
      height: 200px;
      width: 500px;
      padding: 20px;
      border-radius: 5px;
      background: white;
      position: relative;
      color: #00171f;
      // box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      .profile-img {
        height: 100px;
        width: 100px;
        // background: url('https://images.unsplash.com/photo-1504933350103-e840ede978d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=564&q=80');
        background-size: cover;
        background-position: center;
        position: absolute;
        top: -25px;
        left: 5px;
        background-color:  #021742;
        border-radius: 50%;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        img {
          height: 120px;
          width: 80px;
          position: absolute;
          top: 5px;
          left: 10px;
          // border-radius: 50px;
        }
      }
      h3 {
        // font-family: "Open Sans", sans-serif;
        font-family: "Teko", sans-serif;

        text-align: right;
        margin-bottom: 20px;
      }
      .description {
        margin-bottom: 20px;
        margin: 0 20px;
        // font-family: "Open Sans", sans-serif;
        font-size: small;
        // border: 2px solid red;
      }
      .social {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: 2px solid red;

        width: calc(100% - 40px);
        margin: 0 auto;
        a {
          text-align: center;
          border: solid 2px $accent;
          width: 75px;
          padding: 5px 0;
          border-radius: 5px;
          &:hover {
            background: $accent;
            color: white;
            cursor: pointer;
          }
        }
      }
      button {
        width: 80%;
        height: 80px;
        font-size: 2rem;
        margin: 30px 10% 0 10%;
        color: $background;
        border: none;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        background: linear-gradient(
          45deg,
          $accent,
          $otherAccent,
          $otherAccent,
          $accent
        );
        background-size: 300% 300%;
        outline: none;
        transition: all 200ms ease-in-out;
        &:hover {
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
          transform: translateY(2px);
          -webkit-animation: gradientBG 1.5s ease-in-out forwards;
          animation: gradientBG 1.5s ease-in-out forwards;
          cursor: pointer;
        }
        &:active {
          box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
          transform: translateY(4px);
        }
      }
      footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background:  #021742;
        color: white;
        // width: 100%;
        position: absolute;
        left: 20px;
        right: 0;
        bottom: 0;
        height: 30px;
        padding: 0 20px;
        margin-left: -20px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        div {
          display: flex;
          h4 {
            font-family: "Teko", sans-serif;
          }
        }
      }
      @media (max-width: 480px) { 
        // border: 1px solid red;
        width: 100%;
        height: fit-content;
        .description {
          margin-bottom: 40px;
        }
        
    } 
    }
    #team-container:hover{
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    .team {
      display: flex;
      flex-direction: column;
      padding: 20px;

      #login-container {
        height: 200px;
        width: 100%;
      }
    }
  }
}

@-webkit-keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
  // 100% {
  //   background-position: 0% 50%;
  // }
}
@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
  // 100% {
  //   background-position: 0% 50%;
  // }
}
