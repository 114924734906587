.reset-container-new {
  display: flex;
  flex-direction: column;
  width: 100%;

  .login-container .logo img {
    width: 150px;
    height: 120px;
    margin: auto;
  }

  .login-detail {
    height: 400px;
    margin: auto;
    width: 50%;
    border-radius: 4px;
    background-color: white;
    border: none;
    .alert{
      margin: auto;
      width: fit-content;
      padding: 10px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0,0.2);
      border-radius: 10px;

    }
    #forget-pass-form {
      margin-top: 20%;
      .input-field {
        display: flex;
        flex-direction: column;
        input {
          width: 70%;
        }
        .submit-button {
          color: white;
          border: none;
          width: 40%;
          span{
            display: flex;
            justify-content: center;
          }
        }
      }
      @media (max-width: 480px) {
      margin-top: 60%;
      .input-field {
        display: flex;
        flex-direction: column;
        input {
          width: 100%;
        }
        .submit-button {
          color: white;
          width: 100%;
        }
      }

      }
    }
  }
  .login-detail img {
    height: 17px;
    width: 17px;
    margin-right: 10px;
    margin-left: 5px;
    margin-bottom: 6px;
  }
  input {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d1d5db;
    margin: auto;
    background-color: #e5e5e5;
    border-radius: 50px;
    margin-top: 30px;
    border: none;
    outline: none;
    padding-left: 20px;
  }
  .input-field {
    position: relative;
    // border: 1px solid red;
    width: 80%;

    margin: auto;
  }
  .input-field img {
    position: absolute;
    margin-top: -25px;
    right: 20px;
  }

  .buttons {
    width: 80%;
    height: 100%;
    margin: auto;
    text-align: center;
  }

  .buttons button {
    width: 100%;
    border: none;
    outline: none;
    color: white;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .buttons button a {
    text-decoration: none;
    color: white;
  }
  .submit-button {
    width: 80%;
    height: 45px;
    margin: auto;
    border-radius: 4px;
    background-color: #021742;
    margin-top: 30px;
  }

  .submit-button p {
    color: #03c;
    text-align: center;
  }

  .login-detail p {
    text-align: center;
    padding-top: 20px;
  }

  .login-detail p a {
    text-decoration: none;
  }

  .login-bg {
    background-color: #00171f;
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .login-bg div img {
    width: 200px;
    height: 200px;
    margin-left: 40px;
  }
  .login-bg div h1 {
    font-size: 46px;
    font-weight: 600;
    margin-left: 30px;
    margin-bottom: 14px;
  }
  .login-bg div p {
    font-size: 36px;
    margin-bottom: 14px;
    font-weight: 600;
  }
  @media (max-width: 480px) {
    .login-bg {
      display: none;
    }
    .login-detail {
      // border: 1px solid red;
      height: fit-content;
      margin: auto;
      width: 100%;
      #login-form {
        padding-top: 30%;
      }
    }
  }
}
