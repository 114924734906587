@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
  // border: 2px solid red;
}

.dashbord-section {
  background-color: yellow;
  width: 100%;
  display: flex;
  height: 99.5vh;
}

.logo {
  /* margin-left: 15%; */
  width: 100px;
  height: 100px;
}

.dash-content {
  width: 83%;
  background-color: hsla(225, 80%, 92%, 0.993);

  height: 100%;
  @media (max-width: 480px) {
    width: 100%;
  }
}

/* .profile-line{
    background-color: hsla(0, 0%, 0%, 0.132);
    width: 100%;
    height: 1px;
} */
.footer {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  bottom: 3px;
  height: 20px;
  background-color: hwb(225 85% 2% / 0.459);
  padding: 10px 0px;
}

.dash-cases {
  width: 100%;
  height: 90%;
  //  border: 2px solid red;
}

.dash-cases h3 {
  margin-left: 20px;
  //   position: relative;
  top: 10px;
  /* text-align: center; */
  font-size: 19px;
  color: hsla(0, 0%, 0%, 0.548);
}

.cases-table {
  width: 96%;
  //  border: 2px solid red;
  color: hsla(0, 0%, 0%, 0.808);
  background-color: white;
  margin-left: 20px;
  z-index: 1;
  margin-right: 20px;

  height: 95%;
  border-radius: 5px;
  margin-top: 10px;
  h3{
    padding: 5px;
  }
  .add-case{
// border: 1px solid red;
   height: 70%;
   display: flex;
   justify-content: center;
   align-items: center;
   h1{
    display: flex;
    align-items: center;
    img{
      width: 50px;
      cursor: pointer;
    }
    img:hover{
      filter: invert(59%);
    }
   }
  }
  .added-cases {
    width: 100%;
    display: flex;
    padding: 10px;
    gap: 8%;
    .case-card {
      width: 28%;
      padding: 05px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0,0.2);
      
      .case-card-header {
        
        height: 100px;
        // width: 80px;
        .view{
          display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        // border: 1px solid red;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        img {
          width: 50px;
          height: 50px;
          filter: invert(100%);
          cursor: pointer;
          
        }:hover{
            border: 2px solid #021742;
            
          }

        }
        
      }
      
    }
    .case-card:hover{
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0,0.2), 0 6px 20px 0 rgba(0, 0, 0,0.19);

    }
    .case-body{
      p{
        .time{
          font-size: small;
        }
      }
      .actions{
        display: flex;
        justify-content: space-between;
        .edit{
          background-color: #021742;
          border: none;
          padding: 5px;
          color: white;
          text-decoration: none;
          border-radius: 5px;
        }
        .edit span{
          text-decoration: none;
          color: hsla(0, 0%, 100%, 0.715);
        }
        .delete{
          background-color: hsla(0, 100%, 50%, 0.772);
          border: none;
          padding: 5px;
          color: white;
          border-radius: 5px;

        }
      }
    }
    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        .case-card {
          margin: auto;
          gap: 20px;
          width: 90%;
        }
        
      }
      @media (min-width: 768px) and (max-width: 1024px){ 

        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        .case-card {
          margin: auto;
          gap: 20px;
          width: 50%;
        }
        
      
      } 
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
    margin-left: 0px;
    height: fit-content;
  }
  .confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    .confirm-modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      text-align: center;
  
      .confirm-button,
      .cancel-button {
        margin: 10px;
        padding: 10px 20px;
        cursor: pointer;
        border: none;
        border-radius: 5px;
      }
  
      .confirm-button {
        background-color: #d9534f;
        color: white;
      }
  
      .cancel-button {
        background-color: #021742;
        color: white;
      }
    }
  }
  
}

// table {
//   width: 100%;
//   border: 2px solid red;
//   color: hsla(0, 0%, 0%, 0.808);
//   background-color: white;
//   margin-left: 20px;
//   border-collapse: collapse;
//   margin-right: 20px;

//   height: auto;
//   margin-top: 20px;
// }

// tr {
//   /* padding: 20px; */
//   border-bottom: 1px solid hsla(0, 0%, 0%, 0.358);
// }

// tr:last-child {
//   border-bottom: none;
//   /* Remove border from the last row */
// }

// th {
//   text-align: left;
//   /* margin-bottom: 10px; */
//   font-weight: 600;
//   padding-bottom: 12px;
//   width: 14%;

//   color: hsla(0, 0%, 0%, 0.548);
// }

// td {
//   padding-bottom: 12px;
//   font-size: 15px;
//   /* padding: 5px 7px; */
//   width: 14%;
//   border-radius: 5px;
// }

.action {
  display: flex;
  gap: 20px;
  /* border: 1px solid; */
}

.action img {
  width: 22px;
  height: 22px;
  margin-top: 10px;
  border: 1px solid blue;
  padding: 3px;
  border-radius: 3px;
}

.table-line {
  background-color: hsla(225, 80%, 92%, 0.53);
  margin-top: 20px;
  width: 100px;
  height: 1px;
}
