.admin-stats {
  width: 84%;
  height: 87vh;
  margin-top: 1%;
  // border: 1px solid red;
  background-color: #edf1fd;
  .greetings {
    width: 100%;
    // border: 1px solid red;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .profile-card {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #021742;

      .date-time {
        color: white;
        font-size: 50px;
        font-weight: bolder;
      }
    }
    .greetings-card {
      width: 48%;
      height: 30vh;
      // border: 1px solid green;
      position: relative;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7);

      .name {
        position: absolute;
        top: 50%;
        left: 30%;
        font-weight: bold;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .profile-card {
      width: 48%;
      height: 30vh;
      border: 1px solid green;
    }
  }
  .right-cards {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: fit-content;
    // border: 1px solid red;
    gap: 10px;
    padding: 20px;
    .card {
      width: 25%;
      padding: 20px;
      height: 25vh;
      border: 1px solid greenyellow;
      background-color: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7);
      border-radius: 10px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-img{
        border: solid 1px red;
        width: 80px;
        height: 80px;
        border-radius: 50px;
        // position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 60px;
        }
      }
      
    }
    .card:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
}
