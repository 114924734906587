.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  justify-content: center;

  .page-item {
    margin: 0 5px;
    padding: 0px 05px;
    border: 1px solid #ddd;
    cursor: pointer;
    color: #021742;
    background-color: #fff;
    border-radius: 4px;

    &:hover {
      background-color: #f1f1f1;
    }

    &.active {
      background-color: #021742;
      color: #fff;
      border-color: #021742;
    }

    &.disabled {
      cursor: not-allowed;
      color: #ccc;
    }
  }
}
