.staff-nav {
    width: 17%;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    gap: 10px;
    text-transform: uppercase;
    flex-direction: column;
    height: 100%;
    background-color: #021742;
    color: white;
    cursor: pointer;
    /* border-radius: 50px; */
    
     div {
    margin-top: 20px;
    margin-left: 10%;
    gap: 10px;
    display: flex;
  }
  
  .home-staff {
    width: 20px;
    height: 20px;
    filter: invert(70%);
  
  }
  .logo{
    width: 100px;
  }
  @media (max-width: 480px) {
    display: none;
    
  }
  @media (min-width: 768px) and (max-width: 1024px){ 
  
    width: 30%;
    // font-size: large;
  
  } 
  }
  
  