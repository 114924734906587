@import "../../styles/variables.scss";

.service-body {
  min-height: 65vh;
  max-height: fit-content;
  // width: 100%;
  margin-left: 150px;
  margin-right: 150px;
  .service-section {
    // background-color: $third-color;
    margin-top: 30px;

    height: 65vh;
    padding: 15px;
    .heading {
      text-align: center;
      h3 {
        font-size: 2em;
        color: $primary-color; // Example of using a variable from variables.scss
      }

      hr {
        height: 6px;
        width: 80px;
        background-color: #021742;
        border: none; // Remove border
        margin: 10px auto; // Center horizontally with margin
      }
    }
    .cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      // height: 70vh;
      // font-family: "Open Sans", sans-serif;
      // background-color: $third-color;

      .card {
        // height: 300px;
        width: 30%;
        height: 200px;
        background-color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: space-evenly;
        // box-shadow: 2px 2px 2px 0px #021742;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

        border-radius: 10px;
        .home-buttons {
          button {
            align-items: center;
            margin: auto;
            height: 40px;
            width: 200px;
            border-radius: 4px;
            border: none;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

            background-color: white;
            margin-bottom: 10px;
            Link {
              text-decoration: none;
              color: #00171f;
            }
          }
          button:hover {
            background-color: #00171f;
            color: white;
          }
        }
      }
      .card:hover {
        // height: 250px;
        // padding: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .card2 {
        height: 250px;
      }
    }
  }
  @media (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
    .service-section {
      .cards {
        gap: 10px;
        .card {
          width: 100%;
          height: 150px;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
    .service-section {
      .cards {
        gap: 10px;
        .card {
          width: 100%;
        }
      }
    }
  }
}
