@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Saira:ital,wght@0,100..900;1,100..900&family=Teko:wght@300..700&display=swap');
.character-slideshow {
  text-align: center;
  margin-top: 20px;
   /* Adjust the height as per your requirement */
  overflow: hidden; /* Ensures that text does not overflow */
}

.character-slideshow h1 {
  // font-size: 30px;
  color: white;
  // padding: 20px;
  font-size: 45px;
  // font-family: "Open Sans", sans-serif;
  font-family: "Teko", sans-serif;

  
  border-radius: 5px;
  max-width: 800px;
  margin: 0 auto;
  height: 50px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}
